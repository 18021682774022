exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kafegama-qrcode-js": () => import("./../../../src/pages/kafegama/qrcode.js" /* webpackChunkName: "component---src-pages-kafegama-qrcode-js" */),
  "component---src-pages-kafegama-registrasi-seminar-js": () => import("./../../../src/pages/kafegama/registrasi-seminar.js" /* webpackChunkName: "component---src-pages-kafegama-registrasi-seminar-js" */),
  "component---src-pages-kafegama-welcome-js": () => import("./../../../src/pages/kafegama/welcome.js" /* webpackChunkName: "component---src-pages-kafegama-welcome-js" */),
  "component---src-pages-kontak-wa-js": () => import("./../../../src/pages/kontakWA.js" /* webpackChunkName: "component---src-pages-kontak-wa-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

